<template>
    <div class="dashboard">
        <h1>チーム一覧</h1>
        <div class="l-mb4">
            <router-link class="button--oval--primary" :to="{ name: 'teams_create' }" type="button" tag="button">新しいチームを登録する</router-link>
        </div>
        <table>
            <tr>
                <th v-for="data in tableSet" :key="data.key">
                    {{ data.head }}
                </th>
                <th class="is-right">&nbsp;</th>
            </tr>
            <tr v-for="(list, index) in sortingListTeams" :key="`list${index}`">
                <td v-for="data in tableSet" :key="`list${index}${data.key}`">
                    {{ list[data.key] }}
                </td>
                <td class="is-right">
                    <router-link class="button--primary" :to="{ name: 'teams_update', params: { id: String(list.team_id) }}" type="button" tag="button">編集</router-link>
                </td>
            </tr>
        </table>
        <Modal :modal="modal" @action="modalAction($event)" @close="$_modalClose()" />
    </div>
</template>

<script>
    "use strict";

    import apiListTeams from "@/mixins/apiListTeams";
    import common from "@/mixins/common";
    import sorts from "@/mixins/sort";

    const tableTeams = [
        {
            "class": "",
            "head": "チームID",
            "key": "team_id",
        },
        {
            "class": "",
            "head": "チーム名",
            "key": "team_name",
        },
        {
            "class": "",
            "head": "メールアドレス",
            "key": "mail_address",
        },
    ];

    export default {
        "mixins": [
            apiListTeams,
            common,
            sorts,
        ],
        data() {
            return {
                "tableSet": tableTeams,
            };
        },
        "computed": {
            sortingListTeams() {
                // チームIDで昇順
                const list = this.listTeams;
                // const key = "team_id";
                // return this.$_sorting(list, key);
                return list;
            },
        },
        created() {
            this.$_fetchingListTeams();
        },
    };
</script>
