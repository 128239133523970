"use strict";

import common from "@/mixins/common";

export default {
    "mixins": [
        common,
    ],
    data() {
        return {
            "listTeams": [],
        };
    },
    "methods": {
        $_fetchingListTeams(callback) {
            // 全チームデータ取得
            common.$_axios.get(
                `${common.$_configs.apiUrl}/teams`,
                this.$_requestConfig()
            ).then(response => {
                this.listTeams = response.data;
                if (callback) {
                    callback();
                }
            }).catch(() => {
                this.$_modalError("teamFetchError");
            });
        },
    },
};
